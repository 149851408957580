import { Company } from '~/types/types'

export default async function useCompaniesOfUser(): Promise<Company[]> {
    const supabase = useSupabaseClient()
    const user = useSupabaseUser()

    const route = useRoute()

    if (!user.value) {
        throw new Error("User must be authenticated to use this function")
    }

    const { data, error } = await supabase
        .rpc("select_companies_of_user",
            //@ts-ignore
            {
                user_id: user.value.id
            })

    if (error) {
        throw error
    }

    return snakeToCamel(data)
}